body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #efefef;
}

.filter-box { 
  display:inline-block;
  padding: 0 7px;
  margin-right: 1px solid black;
  margin-left: 1px solid black;
}


.homelist {
  display: grid; /* or inline-flex */
  grid-template-columns: repeat(auto-fill, minmax(800px, 1fr));
  background:#efefef;

}

.homeitem {
  display: flex; /* or inline-flex */
  flex-direction: row;
  border: 1px solid #000;
  padding: 5px;
  margin: 10px;
  background: #fff;
}

.home-inactive {
  background: lightgrey;
}
.home-notFav {
  /*background: #fadbd8;*/
}

.homeitem .logosource {
  display: block;
  /* float: right;
  right:0; */
  width: 70px;
  margin: 0 5px 5px 5px;
}

.homeitem .price {
  /*vertical-align: middle;
  margin: auto 10px;*/
  text-align: center;
  font-weight: bold;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin: 5px 10px 10px 10px;
  width:300px;
  display:inline-block;
}

.homeitem .description {
  /*display: flex; /* or inline-flex */
  /*flex-direction: column;*/
  width: 100%;
  margin: 10px;
}

/*.homeitem .description */
.city {
  float:right;
  width: 250px;
  font-size:90%;
}

.homeitem .icon-button {
  margin:5px;
  text-align:center;
  padding:5px;
}

.icon-button-text {
  background: black;
  color: white;
  font-weight: bold;
  padding: 0px 5px;
  border-radius: 10px;
  text-align:center;
  cursor:pointer;
  margin-right: 5px;
}

.icon-button:hover {
  cursor:pointer;
}

.icon-enlighten{
  background-color: darkorange;
  color: white;
  border-radius:20px;
}

.disabled {
  cursor:wait;
  color: red;
}
.disabled:hover {
  cursor:wait;
}

.block {
  display:block;
}

.photo {
  position:relative;
  max-width: 300px;
  margin:auto;
}

.action-bar {
  border-right:1px solid #000;
  margin: -5px 0 0 -5px;
  /*padding: 5px;*/
}

.energie-logo, .ges-logo {
  display:inline-block;
}

.energie-logo .letter , .ges-logo .letter {
  padding:2px 5px;
  border-radius:5px;
}

.energie-logo .letter-A {
  background-color: rgb(55, 153, 50);
  color: #fff;
}
.energie-logo .letter-B {
  background-color: rgb(58, 204, 49);
  color: #fff;
}
.energie-logo .letter-C {
  background-color: rgb(205, 253, 51);
}
.energie-logo .letter-D {
  background-color: rgb(251, 234, 73);
}
.energie-logo .letter-E {
  background-color: rgb(252, 204, 47);
}
.energie-logo .letter-F {
  background-color: rgb(251, 156, 52);
  color: #fff;
}
.energie-logo .letter-G {
  background-color: rgb(250, 28, 31);
  color: #fff;
}



.ges-logo .letter-A {
  background-color: rgb(246, 237, 254);
}
.ges-logo .letter-B {
  background-color: rgb(228, 199, 251);
}
.ges-logo .letter-C {
  background-color: rgb(210, 173, 241);
}
.ges-logo .letter-D {
  background-color: rgb(210, 173, 241);
}
.ges-logo .letter-E {
  background-color: rgb(183, 122, 233);
  color: #fff;
}
.ges-logo .letter-F {
  background-color: rgb(166, 89, 233);
  color: #fff;
}
.ges-logo .letter-G {
  background-color: rgb(136, 53, 217);
  color: #fff;
}

.heart-icon{
  padding: 0 1px;
  color: IndianRed;
  cursor: pointer;
}

.filter-banner , .sort-banner{
  background: #fff;
  margin: 10px 0;
  padding: 5px 10px;
  border-radius: 3px;
  border-left: 2px solid #aaa;

}

.filter-banner .icon-button {
  margin-left: 4px;
  margin-right: 4px;
}

.comment {
  display: inline-block;
  min-width: 200px;
  border: 1px solid #aaa;
}


.spin{
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.radon-1 {
  color: green
}
.radon-2 {
  color: orange
}
.radon-3 {
  color: red
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}


@media screen and (max-width: 800px) {
  body {
    margin-left: 0px;
    margin-right: 0px;
    background: #efefef;
  }
  .homelist {
    display: block;
  }
  
  .homeitem {
    display:block;
    border: 1px solid #000;
    padding: 5px;
    margin: 10px 0;
  }
  .action-bar{
    float:left;
  }

  .photo{
    position: static;
    display: block;
    max-width: 200px;
  }

  .city {
    margin-right: 5px;
  }

}
